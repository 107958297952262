@import "./../../utils/styles/scss/main.scss";

.searchInputs {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  margin-bottom: 1rem;

  // margin-left:23rem;

  @include breakpoint-down(small) {
    align-items: center;
    width: 100%;
    padding: 0.2rem;
    border-radius: 0.2rem;
    margin-left: 0rem;
  }

  input {
    border-radius: 0.5rem;
    width: inherit;
    border: none;
    width: 100%;
    box-shadow: none;
    @include breakpoint-down(small) {
      width: inherit;
      // margin-left:2rem;
    }
  }
  button {
    border-radius: 1rem;
    padding: 0.2rem;
    margin-left: 0.2rem;
    border: none;
  }
}

.searchInputs {
  display: flex;
  justify-content: space-between;
  width: 30rem;
  @include breakpoint-down(small) {
    width: 100%;
  }
}
.searchInputs .searchIcon svg {
  text-align: end;
}

.blog {
  cursor: pointer;
  position: relative;
  text-align: start;
  color: white;
  margin-bottom: -5rem;
  img {
    &:hover {
      -webkit-transform: scale(1.03);
      transform: scale(1.02);
    }
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    width: 350px;
    height: 250px;
    min-height: 100%;
    object-fit: cover;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 0.4rem;
    filter: brightness(40%);
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    @include breakpoint-down(small) {
      width: 100%;
      height: 250px;
    }
  }
  .title-card {
    position: relative;
    top: -4rem;
    left: 57%;
    bottom: 68%;
    transform: translate(-50%, -50%);
    .header {
      font-size: 1rem;
    }

    span {
      margin-bottom: 0.1rem;
    }
  }
}

.detailBlog {
  margin-top: 1rem;
}

.bannerBlogImage {
  vertical-align: middle;
  width: 100%;
  object-fit: cover;
  height: 250px;
  border-radius: 0.1px;
  @include breakpoint-down(small) {
    height: 150px;
  }
}

.headerBox {
  cursor: pointer;
  position: relative;
  text-align: start;
  margin: 0;
  height: 26rem;
  @include breakpoint-down(small) {
    height: 14rem;
  }

  .header-card {
    border: transparent 2px #ccc;
    border-radius: 0.3rem;

    width: 560px;
    background-color: white;
    position: relative;
    top: -1rem;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0.5rem;
    @include breakpoint-down(small) {
      width: 300px;
    }
  }

  .headerh2 {
    margin: 0 auto;
    width: 560px;
    padding: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    hyphens: auto;
    font-weight: bold;
    line-height: 1.4;
    font-size: 2.6rem;
    @include breakpoint-down(small) {
      width: 300px;
      font-size: 1.2rem;
    }
  }
  .authorBar {
    display: flex;
    align-items: center;
    .imgbox img {
      padding: 0.2rem;
      margin-right: 0.5rem;
      display: flex;
      align-items: center;
    }
    small {
      font-size: 0.8rem;
    }
  }
}
.blogBody {
  margin-top: -6rem;
  margin-left: 16%;
  display: flex;
  justify-items: center;
  text-align: start;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  width: 70%;
  line-height: 1.9rem;

  @include breakpoint-down(small) {
    margin-top: -4rem;
    margin-left: 0%;
    width: 100%;
    line-height: 1.6rem;
  }
}

.categories {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1.3rem;
  @include breakpoint-down(small) {
    display: flex;
    justify-content: start;
    margin-bottom: 0rem;
  }
  span {
    font-size: 0.9rem;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;

    &:hover {
      background-color: $BES_PURPLE !important;
      color: white !important;
    }

    @include breakpoint-down(small) {
      font-size: 0.6rem;
    }
  }
}

.postTitleInList {
  height: 10px;
  margin: 0 auto;
  width: 1005;
  margin-bottom: 0.2rem;
  /* padding: 5px; */
  margin-right: 7rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* line-height: 1.4; */
  /* font-size: 1rem; */
  font-weight: 700;
  text-transform: uppercase;
  @include breakpoint-down(small) {
    // width: 300px;
    // font-size: 1.2rem;
  }
}

.searchSection {
  &__title {
    font-size: 1.7rem;
    font-weight: 800;
    &:hover {
      color: $BES_PURPLE;
    }
  }
  img {
    object-fit: cover;
    width: 350px;
    height: 250px;
    margin: 1rem;
    /* margin-left: 0.3rem; */
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
    border-radius: 0.4rem;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
    box-shadow: rgb(0 0 0 / 10%) 0px 0px 5px 0px,
      rgb(0 0 0 / 10%) 0px 0px 1px 0px;
  }

  p {
    margin: 0;
  }
}

.contentOnSearch {
  // height: 10px;
  margin: 0 auto;
  width: 100%;
  margin-bottom: 0.2rem;
  /* padding: 5px; */
  // margin-right: 7rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  hyphens: auto;
  /* line-height: 1.4; */
  /* font-size: 1rem; */
  font-weight: 200;
  font-size: 0.9rem;
  @include breakpoint-down(small) {
    // width: 300px;
    // font-size: 1.2rem;
  }
}

.searchSectionBox {
  &:hover {
    background-color: whitesmoke;
  }
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  cursor: pointer;
}

.categories_scroll {
  overflow-x: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.categories_scroll::-webkit-scrollbar {
  display: none;
}

.test-search {
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint-down(small) {
    justify-content: none;
    align-items: none;
    display: block;
    margin-top: -2.5rem;
  }
}

.social_icons_column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute !important;
  left: 2rem;
  margin-bottom: 0;
  top: 35rem;
}

.social_icons_row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}
