.about-us-title {
    font-size: 60px;
    font-weight: bold;
    margin-top: 30px;

}

.about-us-description {
    font-size: 15px;
    width: 500px;
    text-align: justify;
    line-height: 2;
}

.text-justify {
    text-align: justify;
}

.about-us-card {
    cursor: pointer;
    border: 1px solid lightgrey;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.about-us-card:hover {
    transform: translateY(-10px);
    transition: 0.3s ease-in-out;

}

.card-text-title {
    font-size: 20px !important;
    font-weight: bold !important;
}

.card-text-description {
    font-size: 13px;
    line-height: 1.7em;
}

.otro-blockquote {
    font-size: 1.2em;
    width: 100%;
    /* margin:50px auto; */
    font-family: Open Sans;
    font-style: italic;
    color: #555555;
    padding: 1.2em 30px 1.2em 75px;
    border-left: 8px solid #5034FF;
    line-height: 1.4;
    position: relative;
    background: whitesmoke;
}

.otro-blockquote::before {
    font-family: Arial;
    content: "\201C";
    color: gold;
    font-size: 4em;
    position: absolute;
    left: 10px;
    top: -10px;
}

.otro-blockquote::after {
    content: '';
}

.otro-blockquote span {
    display: block;
    color: #333333;
    font-style: normal;
    font-weight: bold;
    margin-top: 0.8em;
}

@media screen and (max-width: 480px) {
    .about-us-title {
        margin-top: -50px;
        font-size: 45px;
        text-align: center;
    }

    .about-us-description {
        width: 100%;
        padding: 10px !important;
        line-height: 1.7;
    }

    .about-us-card {
        margin: 0 auto;
        margin-bottom: 10px;
    }
}
