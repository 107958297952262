@import "./../../utils/styles/scss/main.scss";


.row-box-shadow{
   
   
    @include breakpoint-up(large){
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
        border-radius: 0.6rem;
        padding: 0.5rem;
        width: 94% !important;
        margin-left: 2rem !important;
        /* box-shadow: 1px -2px 8px 0px rgb(230 222 222 / 56%); */
        /* -webkit-box-shadow: 1px -2px 8px 0px rgb(187 183 183 / 56%); */
        padding: 1rem;
    }

}


svg{
    cursor: pointer;
}
.address-bar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .box{
        padding: 1.2rem;
        width: 21rem;
        border: 2px solid #433B80;
        border-radius: 1rem;
        @include breakpoint-up(large){
            width: 26rem; 
        }
        &_header{
            margin-bottom: 1rem;
            svg,
            h5{
                font-size: 1.2rem;

            }

        }

    }
    label{
        font-size: 1rem;
    }
    textarea{
        font-size: 16px;
        height: 9rem;
        width: 100%
    }
    select{
        width: 100%;
        margin-bottom: 0.5rem;
       
    }
        

}

