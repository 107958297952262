

.jumbotron-header{
    height: 18rem;
    background-color: #433B80;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: start;
}

.accordion-button {
    &:not(.collapsed){
        border: 0.6px thin #F84145 !important;
        box-shadow: 0 0 0 0.1rem #F84145 !important;
        color: #F84145 !important;
    }
}

.accordion-flush .accordion-item .accordion-button {
    &:focus{
       border: 0.6px thin #F84145 !important;
       box-shadow: 0 0 0 0.1rem #F84145 !important;
            color: #F84145 !important;
       }
 }
