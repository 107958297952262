@import "./../../utils/styles/scss/main.scss";


.profile-sidebar{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    border-radius: 0.5rem;
    height: 25rem !important;
    @include breakpoint-down(small){
        display: none;
    }
    
    
}
.custom_container {
    .custom-row{
        display: flex;
        justify-content: flex-start;
        gap: 1rem;
        .first{
            flex: 0.3;
            @include breakpoint-down(medium){
                display: none;
                flex: 0;
            }
        }
        .second{
            flex: 0.7;
            // padding: 1rem;
          

            @include breakpoint-up(medium){
                // padding: 2rem;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
                border-radius: 0.5rem;
            }

            @include breakpoint-down(medium){
                display: block;
                flex: 1;
            }
        }
        
       
    }

}

.nav-tabs{
   
    .nav-link.active{
        color: $BES_RED !important;
        font-weight: 800;
        &:hover{
            color: $BES_RED !important;
        }
        border: 2px solid $BES_RED !important;
        border-top: none !important;
        border-left: none !important;
        border-right: none !important;
        border-bottom-color: $BES_RED !important;


    }
    
    li{
        border: none !important;
        button{
            
            border-top: none !important;
            border-left: none !important;
            border-right: none !important;
        }
        .nav-link{
            color: $BLACK;
    
        }
        
        

        // border-bottom: 2px solid #F84145;
    }
    li:last-child {
        @include breakpoint-down(small){
            width: fit-content;
        }
      }
      
}

.status_bg{
    background-color: $BES_PURPLE;
}


.tab-content{
    overflow: scroll !important;
    height: 24rem !important;
    width: 100%;
}
.tabs-mobile{
    @include breakpoint-down(small){
        display: flex !important;
        /* flex-direction: column !important; */
        justify-content: start !important;
        align-items: start !important;
        width: 21rem;
        flex-wrap: nowrap;
        font-size: 0.8rem;
    }

}
