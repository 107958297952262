.load-cart-shipping{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .box{
        padding: 1.2rem;
        width: 21rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 1rem;
}
}

.cart-box{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 0.5rem;
}

.cart-empty{
    height: 31rem;
    overflow: hidden; 
    border-radius: 0.5rem;
}