@import "./../../utils/styles/scss/main.scss";

.svg-home-icon {
    cursor: pointer;
}

.svg-icon:hover {
    opacity: 0.8;
}

.action-button:hover {
    opacity: 0.9;
}

.banner {
    background: #FED6D6;
    height: 420px;

    @include breakpoint-down(medium){
        height: fit-content;
        img{
            display: block;
        }
    }
}

.home-title-container {
    display: flex;
    align-items: center;
}

.btn-secondary {
    z-index: 999;
}

.btn-secondary:hover {
    color: #F84145 !important;
}

.action-btn-container{
    display: flex;
    justify-content: space-between;
    width: 380px;
    height: 70px;
    gap: 1rem;
    align-items: center;


    @include breakpoint-down(medium){
        justify-content: center;
        width: 100%;
        height: 70px;
        align-items: start;
    } 
    @include breakpoint-down(small){
        justify-content: center;
        width: 304px;
        height: 70px;
        align-items: start;
    } 
}

.action-button {
    z-index: 999;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fade-in {
    animation: fadeIn 2s ease-out forwards;
}

@media screen and (max-width: 480px) {
    .banner {
        // padding-top: 0 !important;
        margin-bottom: 15px;
    }

    .home-title-container {
        margin-top: 10px;
        @include breakpoint-down(small){
            margin-top: 0px;
        } 
    }

    .text-description {
        font-size:14px;
    }

    .action-button {
        border: none !important;
        border-radius: 10px !important;
        font-weight: normal !important;
        width: 200px !important;
        margin-bottom: 15px;
        font-size: 15px;
    }
    .home-svg {
        display: none !important;
        /* width: 100%; */
    }

    .hero-div {
        margin-bottom: 5px !important;
    }

    .svg-home-icon {
        margin-bottom: 40px !important;
    }
    .store-column {
        overflow: hidden !important;
        margin-left: 12vw;
        @include breakpoint-down(small){
            margin-left: 1.4rem !important;
            .store-card{
                width: 100px !important;
                height: 100px !important;
                margin-left:1rem;
    
                .card-body img{
                    width: 100px !important;
                    height: 100px !important;
                }
            }
        }
    }
    .home-hero-header {
        font-size: 36px !important;
        margin-top: 20px !important;

    }

    .svg-banner {
        width: fit-content !important;
        margin-top: 20px ;
    }
}

.store-card:hover {
    opacity: 0.7;
    transition: all 0.2s;
}

.svg-banner {
    background-color: #E9E9E9;
    border-radius: 10px;
}
.home-stores-list{
    display: flex;
    /* align-items: center; */
    /* text-align: center; */
    flex-wrap: wrap !important;
    justify-content: center !important;

    @include breakpoint-up(large){
        margin-left: 5rem;
    }

    @include breakpoint-down(small){
        justify-content: start;
     
    } 
}
.shopping-icons{
    justify-content: center;
 
}
.item-mobile{
    @include breakpoint-down(small){
    margin-top: -1.5rem;
    }
}
