@import "./../../utils/styles/scss/main.scss";

.account_box{
    border: none;
    height: 100%;
    width: 90%;
    padding: 1.2rem;
    border-radius: 0.4rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    background-color: $BES_PURPLE;
    color: $WHITE;

    @include breakpoint-up(large){
        padding: 1rem;
        height: 10rem;
        width: 32rem;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
        background-color: $BES_PURPLE;
    }
}

.table-responsive{
    overflow-x: auto;
    height: 21rem !important;
}