ul:last-child {
    margin-top: 50px;
}
ul:last-child:before {
    content: " ";
    display: block;
    width: 80%;
    margin: 0 auto;
}
.side-navigation-panel {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 480px) {
    ul:last-child {
        margin-top: 10px;
    }

    .side-navigation-panel {
        width: 100%;
        height: 40vh;
}

}
/* .side-navigation-panel .side-navigation-panel-select .side-navigation-panel-select-wrap .side-navigation-panel-select-option-selected{
    --tw-border-opacity:0 !important;
} */
