body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --light: #d0d1d3;
  --dark: #040d14;
  --purple: #5034FF;
  --green: #21ce99;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
  color: #433B80;
}

.signup-btn {
  background-color: #433B80 !important;
  transition: 0.3s ease-in-out;
  color: #fff !important;
  border: none;
}

.bes-input-box-shadow:focus {
  outline:none;
    box-shadow: 0 0 0 1px #433B80;
}

.description {
  text-align: justify !important;
  line-height: 25px;
}

ol.normal {
  list-style-type: lower-alpha;
}

li {
  line-height: 2rem !important;;
}

.title {
  margin-bottom: 20px;
}

.desc-img {
  width: 700px;
}


.left-panel {
  background-color:var(--purple);
  margin-left: 0px;
}

.stores-card:hover {
  opacity: 0.8;
  background-color: whitesmoke;
  transition: all 0.2s ease;
}

.search-oval {
  margin-right: 10px !important;
}

.link:hover {
  text-decoration: none;
  color: #F84145;
  transition: all 0.4s ease;
}

.modal-header {
  border-bottom: 0 none !important;
}

.modal-header > button {
  background-color: #F84145 !important;
  border-radius: 50%;
  opacity: 0.9 !important; 
}

.modal-header > button:hover {
  opacity: 0.7 !important;
}

/* Media Queries */

.load-cart-button:hover {
    opacity: 0.9 !important;
}

@media (max-width: 480px) {
  .add-icon {
    margin-top: 10px !important;
  }

  .profile-sidebar {
    margin-bottom: 25px;
  }

  .profile-form-input {
    width: 80vw;
  }

  .pending-orders-card {
    width: 20rem !important;
  }
  .load-cart-description {
    width: fit-content !important
  }
  .load-cart-form {
    width: 100% !important
  }
  .load-cart-button {
    width: 100% !important
  }
}

#progressBarContainer {
  position: fixed;
  z-index: 10;
  background: rgba(255, 255, 255, 0.05);
  width: 100%;
  top: 0;
  left: 0;
}


#progressBar {
  background: linear-gradient(to left, red, violet);
  transform-origin: top left;
  transform: scale(0, 0);
  opacity: 0;
}

#progressBarContainer,
#progressBar {
  height: 5px;
}

