@import '../../utils/styles/scss/main.scss';
.PhoneInputCountry {
    margin-left: 20px;
}

.PhoneInputInput {
    border-width: 0;
}

.PhoneInput--focus {
    outline: none;
}

textarea:focus, input:focus {
    outline: none;
}

.signup-btn {
  border: none;
  border-radius: 10px !important;
  font-weight: normal !important;
}

.signup-btn:hover {
  opacity: 0.8;
  transform: translateY(-0.5px) !important;
}

.signup-icon-description {
  margin-left: 15px;
  font-size: 14px;
  color: #fff;
  
}

.signupFormInput:focus {
  outline: none;
  box-shadow: 0 0 0 1px #5034FF;
  color: #333;
}

@media (max-width: 480px) {
  .mobile-button-responsive {
    width: 100% !important;
    margin-top: -10px !important;
  }
  .signup-right-container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  
  }
  .signup-margin-top{
    @include breakpoint-down(small){
      margin-top: 379px !important;
    }
  }
  .signup-left-container {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .email-input {
    margin-top: 30px !important;
  }

  .register-form {
    margin-bottom: 15px !important;
  }
}

#logo_googleg_48dp{
  border-radius: 50px !important;

}


.google-login{
  width: inherit !important;
  height: 53px !important;
  background-color: #ffff !important;
  color: $BES_PURPLE !important;
  border-radius: 0.5rem;
  box-shadow: none !important;
  font-weight: 500;
  border: 1px  solid $BES_PURPLE !important;
  border-color: $BES_PURPLE !important;
  border-radius: 5px !important;

  span{
    margin-right: 3.4rem;
  }
  &-extend{
    width: 507px !important;

  }
  &:hover{
    background-color: $BES_PURPLE !important;
    color: whitesmoke !important;
  
  }

  @include breakpoint-down(medium) {
    width: 339px !important;
  }
}


.form-description{
  color:#3F474B;
  font-weight: 500;
  font-size: 16;
  margin-top: 30;
  text-align: center;
}

.form-button{
  width: 100%;
  height: 53px !important;
  display: block;
  background: #ffff;
  border-radius: 5px;
  color: $BES_PURPLE;
  border: 1px  solid $BES_PURPLE;
  border-color: $BES_PURPLE;
  cursor: pointer;
  
  &:hover{
    background-color: $BES_PURPLE !important;
    color: whitesmoke !important;
  
  }


}
