@import "../../utils/styles/scss/main.scss";

.main-auth-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @include breakpoint-down(small) {
    width: 100% !important;
  }
}

.auth-navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-right: 4rem;
  margin-top: 4rem;
}

.main-right {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  flex: 0.6;
  overflow: hidden !important;
  background: linear-gradient(to bottom, #2d265b, #544d9d) !important;
  @include breakpoint-down(small) {
    display: none !important;
  }
}

.main-left {
  flex: 0.6;
  overflow: scroll !important;
  @include breakpoint-down(small) {
    flex: 1;
  }
}
.main-content-box {
  overflow-x: scroll !important;
}
