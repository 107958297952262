.social-icon-instagram {
    color: tomato;
    transition: 0.2s ease;
}

.social-icon-facebook {
    color: #4267B2;
    transition: 0.2s ease;
}

.social-icon-twitter {
    color: #1DA1F2;
    transition: 0.2s ease;
}

.social-icon-linkedin {
    color: #0077b5;
    transition: 0.2s ease;
}
.social-icon-instagram:hover {
    color: tomato;
    transition: 0.2s ease;
    opacity: 0.8;
}

.social-icon-facebook:hover {
    color: #4267B2;
    transition: 0.2s ease;
    opacity: 0.8;
}

.social-icon-twitter:hover {
    color: #1DA1F2;
    transition: 0.2s ease;
    opacity: 0.8;
}

.social-icon-linkedin:hover {
    color: #0077b5;
    transition: 0.2s ease;
    opacity: 0.8;
}

.footer-links:hover {
    color: #F84145 !important;
    transition: 0.2s ease !important;
}