@import "../../utils/styles/scss/main.scss";
.custom-carousel {
  position: relative;
  height: 700px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  @include breakpoint-down(smal){
    width: 100%;
    height: 100%;
  }
}

.custom-slide {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  left: 0;

  a{
    text-decoration: none;
    cursor: pointer;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

    @include breakpoint-down(medium){
      object-fit: contain;
    }
  }

}

.custom-active {
  opacity: 1;
}
