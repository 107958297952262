.auth-carousel {
    position: relative;
    width: 100%;
    height: 350px;
    overflow: hidden;
   
  }
  
  .auth-slide {
    position: absolute;
    width:100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    left: 0;
    padding: 1.5rem;
  
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;

    }
  }
  
  .auth-active {
    opacity: 1;
  }
  

  .main-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .main-content-box {
  
    animation: slide-in 0.5s ease-out forwards;
    position: relative;
    right: -200px;
  }
  
  @keyframes slide-in {
    0% {
      left: -200px;
    }
    100% {
      left: 0;
    }
  }