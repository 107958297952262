@import "./../../utils/styles/scss/main.scss";

.convRateContainer{
    @include breakpoint-down(medium){
        width: 70% !important;
    }
}

.mobile-wallet-width{
    @include breakpoint-down(medium){
        width: 100% !important;
    }

}

.mobile-wallet-card{
    @include breakpoint-down(medium){
        background-position: inherit !important;
    }
}