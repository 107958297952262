@import "../../utils/styles/scss/mixins";

.noResult {
  position: absolute;
  z-index: 200;
  background-color: white;
  height: fit-content;
  width: 30rem;
  overflow: hidden;
  text-align: center;
  @include breakpoint-down(small) {
    width: 100%;
  }
}
.dataResult {
  &:hover{
    

  }
  background-color: white;
  width: 30rem;
  overflow-y: scroll;
  height: fit-content;
  position: absolute;
  z-index: 200;

  cursor: pointer;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  @include breakpoint-down(small) {
    width: 100%;
  }
  h6 {
    font-size: 0.75rem;
    padding: 0.5rem;
    cursor: pointer;
    border: 0.2px thin grey;
  }
}

.searchbar-holder {
  display: flex;
  // justify-content: space-evenly;
  align-items: center;
  margin-bottom: 1rem;
  padding: 0.7rem;
  cursor: pointer;
  &:hover{
    background-color: whitesmoke;
  
  }

  img {
    border-radius: 0.3rem;
    vertical-align: middle;
    width: 5rem;
    object-fit: cover;
    background-image: linear-gradient(rgba(0, 0, 0, 0.527), rgba(0, 0, 0, 0.5));
  }
  small {
    font-size: 0.8rem;
  }
}
