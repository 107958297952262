.login-container {
    border: 2px solid #433B80;
    border-radius: 10px;
    padding: 10px 25px 0px 25px;
    /* background-color: #433B80; */
    margin-top: 15px;
    width: 380px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.login-btn {
    background: #433B80 !important;
    color: #fff !important;
}

.login-btn:hover {
    opacity: 0.8 !important;  
}

.login-container-title {
    margin-top: 20px;
    color: #433B80;
    font-size: 35px;
    font-weight: 600;
    text-align: center;
}

.form-group {
    margin-top: -50px;
}

.loginFormInput:focus {
  outline: none;
  box-shadow: 0 0 0 1px #433B80;
}

.login-container-description {
    color: #333 !important;
    font-size: 12px;
}

.carousel-img {
    width:200px;
    margin-top: 25px;
}

/* mobile responsive */

@media screen and (max-width: 768px) {
    .signin-header-text {
        font-size: 30px !important;
        margin-top: 0px !important;
        color:#333 !important
    }
    .carousel {
        display: none;
    }
    /* .main-login-container {
        padding-left:40px;
    } */
    
    .login-container {
        width: 400px !important;
    }

    .text-sm {
        font-size:13px !important;
    }

    .login-container-description {
    color: #333 !important;
    font-size: 11px;
}

}

@media screen and (max-width: 768px) {
    .carousel-img {
        width: 150px;
    }

    .carousel {
        display: none;
    }

}
