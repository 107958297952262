.verify-redirect-header{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    h1{
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.2rem;
    }
    div{
        background: #433B80;
        font-weight: 400;
        width: 20rem;
        border: none;
        color: white;
        &:hover {
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            background-color: transparent;
          
          }

    }
}