/* form */

form {
  margin-top: 20px;
  margin-bottom: 30px;
}

.formRow {
  margin: 10px 0 20px 0;
}

.formInput {
  margin-top:20px;
  padding: 10px 10px;
  background-color: #fff;
  box-shadow: 0 0 0 2px var(--light);
  border: none;
  color: #333;
}

.formInput:focus {
  background-color: #fff;
  outline: none;
  box-shadow: 0 0 0 2px var(--purple);
  color: #333;
}

.formInput::placeholder {
  color: #333;
  opacity: 1;
  font-size: 13px;
}

.formInput:focus::placeholder {
  color: var(--light);
  opacity: 1;
}

.errorMessage {
  color: #f45532;
  font-size: 13px;
  margin: 0;
  padding: 0;
}

#notifyToast {
  color: #fff;
  border-radius: 6px;
  width: 300px;
  background-color: var(--green);
}

.submit-btn {
  background-color: #433B80;
  color: #fff !important;
  padding: 10px 40px;
  border: none;
  border-radius: 10px;
  margin-bottom: 25px;
}

.submit-btn:hover {
  background-color: #fff;
  color: var(--dark) !important;
  border: 2px solid var(--purple);
  transition: 0.3s ease;
  margin-bottom: 20px;
}

.submit-btn:disabled:hover {
  background-color: var(--dark);
  color: var(--green) !important;
  cursor: wait;
}

.location-map {
    border-radius: 10px;
}
.contact-form-container {
    border-radius: 10px;
    padding: 80px 40px 0px 40px;
    margin-bottom: 60px;
    background: #F5F5F5;
}


@media (max-width: 768px) {
  .submit-message {
    width: 125px;
    margin-left: 200px;
  }
}

@media screen and (max-width:480px) {
    .contact-form-right-column{
        margin-top: 25px;
    }
    .contact-form-container {
        padding: 10px;
        margin-bottom: 20px;
    }
}
