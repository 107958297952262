@import "../../utils/styles/scss/main.scss";

@media screen and (max-width: 768px) {
    .logo {
        width: 180px !important;
    }
    .dropdown-link{
        background-color: rgb(244, 244, 244);
    }

}
.navbar-expand-lg .navbar-nav{
    align-items: center;
}


#nav-dropdown-light-example{
    .dropdown-menu .show .dropdown-menu-primary{
        margin-left: -2rem !important;
    }
}
.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    color: #433B80 !important;
    &:hover{
        color: rgb(248, 65, 69);
    }
}

.navbar-light .navbar-nav .nav-link {
    color: #433B80;
    &:hover{
        color: rgb(248, 65, 69);
    }
}
.dropdown-menu{
    background-color: rgb(244, 244, 244) important;

}
.user-icon{
    &:hover{
        color: rgb(248, 65, 69);
    }
    .user-icon-fa{
        border-radius: 1rem;
        font-size: 1rem;
        cursor: pointer;
        color: #433B80;
        &:hover{
            color: rgb(248, 65, 69);
        }
    }
}

.navbar-nav{
    .dropdown-link{
        &:hover{
            color: rgb(248, 65, 69);
        }
        padding: 14px;
        display: flex;
        color: #655f8f;
        font-size: 0.8rem;
        padding-right: 21px;

        gap: 0.2rem;
        align-items: center;
        svg{
            margin-right: 0.5rem;
            font-size: 1rem;
            &:hover{
                color: rgb(248, 65, 69);
            }
        }

    }
}
.mr-1{
    margin-right: 1rem;

}
.custom-style{
    margin-left: 0.5rem !important;
    margin-right: 1rem !important;

}
.cto-button{
    background: #7063d5;
    color: white;
    font-size: 16px;

    &:hover{
        background: rgb(248, 65, 69);
        color: white;


    }
}

.bannerv{
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index:10
}

.lazy-load-image-background .blur .lazy-load-image-loaded{
    display: block !important;
}

#responsive-navbar-nav{
    @include breakpoint-down(small){
    .navbar-nav{
        .nav-links-div{
            margin-left: 0%;
            width: 100%;
        }
        .nav-link{
            width: 100% !important;
            height: 3rem !important;
            
            margin-bottom: 0.5rem;
            padding-right: 0rem !important;
            margin-left: 30%;
    
           .link{
            text-transform: uppercase;
            align-self: center;

         

           }
    
           i, svg{
            margin-right: 0.7rem;
            // margin-top: 0.2rem;
            align-self: center;
            // width:20px !important;
            // height:18px !important;
         
           }
        }
    
    }
}

    @include breakpoint-down(medium){
        i, svg{
            margin-right: 0.7rem;
            // margin-top: 0.2rem;
            align-self: center;
            // width:20px !important;
            // height:18px !important;
         
           }
    }
}
#responsive-navbar-nav .navbar-nav .nav-link .link {
    @include breakpoint-down(medium){
    text-transform: uppercase;
    width: 16rem;
    }
}

.navbar-expand-lg .navbar-nav{
    @include breakpoint-down(small){
    align-items: start;
    padding: 0rem;
    }
}

.navbar-collapse {
    @include breakpoint-down(small){
    flex-basis: 100% !important;
    flex-grow: 1 !important;
    align-items: center !important;
    transition: none !important;
    }
}

.navbar-expand-lg .navbar-nav {

    @include breakpoint-down(small){
        align-items: start;
        padding-left: 0rem;
        padding-bottom: 0rem !important;
    }
  
}


.modal-img{
    width: 450px;
    height: 250px;
    object-fit: contain;
}
.modal-description{
    margin-top: 1rem;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 600;
    padding: 0.2rem;
}
.modal-body-extend{
    @include breakpoint-down(small){
        width: 100%;
    }
    
    color: #433B80;

    img{
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        width: 700px;
        height: 400px;
        @include breakpoint-down(small){
            width: 400%;
            height: 500px
        }
        
    }
}
.modal-footer-header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border:none !important;
    button{
        width: 20rem !important;

    }
}

.modal-content-z{
    z-index: 99999 !important;
}
.modal-content-header{
    z-index: 99999 !important;
    .modal-content{
        right: 6rem !important;
        width: 159% !important;
        @include breakpoint-down(small){
            width: 100%;
            right: 0rem
        }
    }
  
}
.me-auto .navbar-nav{
    .nav-link{
       @include breakpoint-down(small){
        display:flex !important;
        justify-content: center !important;
        align-items: center;
       }
    }

}
.mobile-actions{
    @include breakpoint-up(medium){
        display: none;
    }
    width: 100%;
    height: 64px;
    text-align: center;
    padding: 1rem;
    font-weight: 700;
    &-login{
        background: #EEECEC;

    }
    &-signup{
        background: #3F3B7F;
        a{
            color: white;
        }

    }
}

.navbar{
    .navbar-brand{
        margin-left: 0.5rem;
        border: none !important;
    }
    .user-svg-icon{
        margin-right: 1rem;
        @include breakpoint-up(large){
            display: none;
        }
    }
    .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl{
        @include breakpoint-down(small){
          padding-left: 0rem;
          padding-right: 0rem;
        }
      }
}
