//  BASIC
$PRIMARY: #1779BA;
$SECONDARY: #767676;
$WHITE: #FFFFFF;
$BLACK: #000000;
$ORANGE:#F07B48;

// BES
$BES_PURPLE: #433B80;
$BES_RED:#F84145;


$SUCCESS:#3ADB76;
$WARNING:#FFAE00;
$ALERT:#F84145;


// GRAYSCALE
$GRAY_LIGHT:#E6E6E6;
$GRAY_MEDIUM:#CACACA;
$GRAY_DARK:#8A8A8A;

