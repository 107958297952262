//flexbox
.flex {
    display: flex;
    &-jc-sb {
      justify-content: space-between;
    }
    &-jc-c {
      justify-content: center;
    }
    &-jc-se {
      justify-content: space-evenly;
    }
    &-ai-c {
      align-items: center;
    }
    &-dc {
      flex-direction: column;
    }
    &-jc-l {
      justify-content: left;


    }
  }
  
  .mobile {
   &-cart{
    @include breakpoint-down(small){
      padding-left: 2rem;
      padding-right: 2rem
  
    }
   ;
   }
  }

.bes_purple{
  background-color: #433B80!important;

  &-color{
    color: $BES_PURPLE !important;

  }
}

.cursor-toggle{
  cursor: pointer;
}


.font-normal{
  font-size: 2rem !important;

}

.custom_container {

  @include breakpoint-down(small){
    width: 100%;

  }
  @include breakpoint-up(medium) {
    max-width: 66.375rem;
    margin: 0 auto;
  }
    &--pall {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
  
      @include breakpoint-up(medium) {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
     &--py {
      padding-top: 4.375rem;
      padding-bottom: 4.375rem;
      @include breakpoint-up(medium) {
        padding-top: 6rem;
        padding-bottom: 6rem;
      }
    }
    &--px {
     @include breakpoint-up(medium){
      padding-right: 10rem;
      padding-left: 1.5rem;
     }

    }
    &--pt {
      padding-top: 3.375rem;
      @include breakpoint-up(medium) {
        padding-top: 6rem;
      }
    }
  
    &--pb {
      padding-bottom: 4.375rem;
      @include breakpoint-up(medium) {
        padding-bottom: 6rem;
      }
    }
    &--pr {
      padding-right: 1.5rem;
    }
    &--pl {
      padding-left: 1.5rem;
    }
    &--pb {
      padding-bottom: 4.375rem;
      @include breakpoint-up(medium) {
        padding-bottom: 6rem;
      }
    }
  }
  

  .hide-for-mobile{
    @include breakpoint-down(medium){
      display: none !important;
    }
  }


  .hide-for-desktop{
    @include breakpoint-up(large){
      display: none !important;
    }
  }

  
  .cusor-me {
    cursor: pointer;
  }