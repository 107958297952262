@import "./../../utils/styles/scss/main.scss";

.profile_box{
    border: none;
    height: 100%;
    width: 90%;
    padding: 1.2rem;
    border-radius: 0.5rem;
    // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    background-color: $BES_PURPLE;
    color: $WHITE;
    background-image: url('../wallet/assets/background.png');
    background-repeat: no-repeat;
    background-position: right;
    background-size: cover;
    
    label{
        color: white !important;
        font-size: 0.7rem;
        text-transform: uppercase;
    }

    @include breakpoint-up(large){
        padding: 1rem;
        height: 20rem;
        width: 100%;
        // box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
    }
}


.bes-purple{
    border-radius: 0.5rem;

    &-color{
        color: $WHITE;
    }

    &-outline-w{
        color:$WHITE;
        background-color: $BES_PURPLE;

        &:hover{
            background-color: $WHITE;
            color:$BES_PURPLE;
            border: 1px solid $BES_PURPLE;
            
        }
    }

    &-outline-p{
        color:$BES_PURPLE;
        background-color: $WHITE;
        border: 1px solid $BES_PURPLE;

        &:hover{
            background-color: $BES_PURPLE;
            color:$WHITE;
            border: 1px solid $BES_PURPLE;
            
        }
    }
    
    &-text{
        color: $BES_PURPLE;


    }

     
    &-bg{
        background-color: $BES_PURPLE !important;

        
    }
      
    &-text-bg{
        background-color: $BES_PURPLE !important;
        color: $WHITE !important;

        
    }
}